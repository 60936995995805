import { useAuth } from '@/composables/auth'
import axios from 'axios'
import router from '@/router'
import type { ApiErrorResponse } from '@/types/apiResponse'

const cacheApi = axios.create({
  baseURL: import.meta.env.VITE_CACHE_API_URL
})

cacheApi.interceptors.request.use(
  (config) => {
    const { accessToken } = useAuth()
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
cacheApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        if (error.response.status === 403) {
          const { set } = useAuth()
          set('token', '')
          router.push('/login')
        }
        // The request was made, but the server responded with an error
        const errorResponse: ApiErrorResponse = error.response.data
        return Promise.reject(errorResponse)
      } else if (error.request) {
        // The request was made, but no response was received
        return Promise.reject(new Error('No response received from the server.'))
      } else {
        // Something happened in setting up the request that triggered an Error
        return Promise.reject(new Error(error.message))
      }
    }
  }
)
export default cacheApi
