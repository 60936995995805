import api from '@/core/api'
import { toast } from 'vue3-toastify'
import type { ApiResponse, ApiErrorResponse } from '@/types/apiResponse'
import { handleError } from '@/helpers/handleError'

export async function deleteAttributeOption(
  id: number,
  attribute_id: string
): Promise<ApiResponse> {
  try {
    await api.delete(`/wp-json/wc/v3/products/attributes/${attribute_id}/terms/${id}?force=true`)
    toast.success(`تم حذف الخيار بنجاح`)
    return {
      success: true
    }
  } catch (error: any) {
    handleError(error)
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
