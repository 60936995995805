import api from '@/core/api'
import type { ApiResponse, ApiErrorResponse } from '@/types/apiResponse'
import { toast } from 'vue3-toastify'
import { handleError } from '@/helpers/handleError'

export async function deleteProduct(id: number | string): Promise<ApiResponse> {
  try {
    await api.delete(`/wp-json/wc/v3/products/${id}`)
    toast.success(`تم حذف المنتج بنجاح`)
    return {
      success: true
    }
  } catch (error: any) {
    handleError(error)
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
