import api from '@/core/api'
import type { ApiResponse, ApiErrorResponse } from '@/types/apiResponse'
import { handleError } from '@/helpers/handleError'
import { toast } from 'vue3-toastify'

export async function updateOrders(payload: any): Promise<ApiResponse> {
  try {
    const { status } = await api.post(`/wp-json/wc/v3/orders/batch`, payload)

    if (payload.update) {
      if (payload?.update[0]?.status == 'trash') toast.success(`تم حذف الطلب / الطلبات بنجاح`)
      else toast.success(`تم استعاده الطلب / الطلبات بنجاح`)
    } else toast.success(`تم حذف الطلب / الطلبات بنجاح`)
    return {
      success: true
    }
  } catch (error: any) {
    handleError(error)
    console.log(error)
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
