<script setup lang="ts">
import navigationItems from '@/navigation'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { useCookies } from '@vueuse/integrations/useCookies'
import { useOrdersStore } from '@/stores/orders'

const ordersStore = useOrdersStore()
const baseUrl = import.meta.env.VITE_SERVER_API_URL
const { user, setUser } = useUserStore()
const { remove } = useCookies(['token'])
const router = useRouter()
const rail = ref(false)
const selectedNavItem = ref()

const logout = () => {
  remove('token')
  setUser(null)
  router.push('/login')
}

function resetPassword() {
  window.open(baseUrl + '/my-account/edit-account/?login=true&type=forgot-password')
}

function editProfile() {
  window.open(baseUrl + '/my-account/edit-account/?login=true')
}
</script>

<template>
  <v-navigation-drawer
    :rail="rail"
    mobile-breakpoint="md"
    style="position: fixed; min-width: 60px"
    width="250"
  >
    <v-list>
      <v-list-item :title="user?.displayName" :subtitle="user?.email" style="text-align: center">
        <template #prepend>
          <v-btn
            variant="text"
            v-if="rail"
            icon="mdi-chevron-left"
            @click.stop="rail = !rail"
          ></v-btn>
          <v-row>
            <v-col>
              <v-menu>
                <template v-slot:activator="{ props }">
                  <span
                    class="mdi mdi-dots-vertical"
                    style="font-size: x-large"
                    v-bind="props"
                  ></span>
                </template>
                <v-list>
                  <v-list-item
                    title="تعديل الحساب"
                    prepend-icon="mdi-store-edit"
                    active-class="active-nav"
                    @click="editProfile"
                  ></v-list-item>
                  <v-list-item
                    title="تعديل كلمه السر"
                    prepend-icon="mdi mdi-lock-reset"
                    active-class="active-nav"
                    @click="resetPassword"
                  ></v-list-item>
                  <v-list-item
                    title=" تسجيل خروج "
                    prepend-icon="mdi-logout-variant"
                    active-class="active-nav"
                    @click.prevent="logout"
                  ></v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </template>
        <template v-slot:append>
          <v-btn
            variant="text"
            v-if="rail"
            icon="mdi-chevron-left"
            @click.stop="rail = !rail"
          ></v-btn>
          <v-btn variant="text" v-else icon="mdi-chevron-right" @click.stop="rail = !rail"></v-btn>
        </template>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list class="mt-1">
      <div
        v-for="navigationItem in navigationItems"
        :key="navigationItem.id"
        :data-cy="`navbar-${navigationItem.key}`"
        @click="selectedNavItem = navigationItem.id"
      >
        <div v-if="navigationItem?.subItems?.length > 0">
          <v-list-group>
            <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                base-color="black"
                @click="rail = false"
                :title="navigationItem.title"
                class="nav-item"
                :active-class="!rail ? 'active-nav-main' : 'active-nav'"
                :prepend-icon="navigationItem.icon.icon"
              >
                <v-badge
                  color="success"
                  dot
                  v-if="ordersStore.showBadge && navigationItem.key == 'orders'"
                  style="position: absolute; top: 0px; right: 30px"
                />
              </v-list-item>
            </template>
            <div v-if="!rail" style="padding-right: 16px">
              <div style="border-right: 2px solid #d9e1e5; padding-right: 5px">
                <v-list-item
                  class="nav-item"
                  v-for="item in navigationItem?.subItems"
                  :key="item.id"
                  :title="item.title"
                  :to="item.to"
                  :prepend-icon="item.icon.icon"
                  style="padding-right: 16px !important; min-height: 42px !important"
                  active-class="active-nav"
                >
                  <v-badge
                    v-if="ordersStore.showBadge && item.key == 'newOrders'"
                    color="success"
                    dot
                    style="position: absolute; top: 0px; right: 30px"
                  />
                </v-list-item>
              </div>
            </div>
          </v-list-group>
          <div v-if="rail" class="container" @click="rail = false">
            <div class="vertical-line"></div>
            <div v-if="selectedNavItem == navigationItem.id" class="point"></div>
          </div>
        </div>
        <v-list-item
          v-else
          class="nav-item"
          :title="navigationItem.title"
          :to="navigationItem.to"
          active-class="active-nav"
          style="min-height: 42px !important"
          :prepend-icon="navigationItem.icon.icon"
        ></v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>
<style>
.flex {
  display: flex;
  align-items: center;
}
.container {
  position: relative;
  width: 56px;
  height: 85px;
}

.v-badge--dot .v-badge__badge {
  border-radius: 50px !important;
  height: 15px !important;
  min-width: 0;
  padding: 0;
  width: 15px !important;
}
.vertical-line {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #333;
  content: '';
}
.point {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 5px;
  height: 5px;
  background-color: #333;
  border-radius: 50%;
}
@media screen and (min-width: 700) {
}
</style>
