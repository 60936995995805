import api from '@/core/api'
import type { ApiResponse, ApiErrorResponse } from '@/types/apiResponse'
import { handleError } from '@/helpers/handleError'
import { toast } from 'vue3-toastify'

export async function deleteCategory(id: number): Promise<ApiResponse> {
  try {
    await api.delete(`/wp-json/wc/v3/products/categories/${id}?force=true`)
    toast.success(`تم حذف التصنيف بنجاح`)
    return {
      success: true
    }
  } catch (error: any) {
    handleError(error)
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
