import { createRouter, createWebHistory } from 'vue-router'
import { useCookies } from '@vueuse/integrations/useCookies'
import { useProductStore } from '@/stores/product'
import { useAuth } from '@/composables/auth'
import DefaultLayout from '@/layout/DefaultLayout.vue'
import { toast } from 'vue3-toastify'

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL || '/'),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 10 }
    }
  },
  routes: [
    {
      path: '/',
      redirect: () => {
        return { name: 'products' }
      },

      name: 'admin',
      component: () => import('@/views/AdminView.vue'),
      children: [
        {
          path: '/products',
          name: 'products',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/product/ProductListView.vue')
        },
        {
          path: 'categories',
          name: 'categories',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/categories/CategoriesView.vue')
        },
        {
          path: 'categories/:id',
          name: 'category',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/categories/CategoryView.vue')
        },
        {
          path: 'categoriesOrder',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/categories/CategoriesOrder.vue')
        },

        {
          path: 'subCategoriesOrder',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/categories/SubCategoriesOrder.vue')
        },

        {
          path: 'attributes',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/attributes/AttributesView.vue')
        },
        {
          path: 'attributes/:id',
          name: 'attributes options',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/attributes/options/AttributeOptionsView.vue')
        },
        {
          path: '/:id/attributeOptionsOrder',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/attributes/options/AttributeOptionsOrderView.vue')
        },
        {
          path: 'galary',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/images/ImagesView.vue')
        },
        {
          path: 'productOrder',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/product/ProductOrder.vue')
        },
        {
          path: 'settings',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/settings/SettingsView.vue')
        },
        {
          path: 'trash',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/trash/TrashView.vue')
        },
        {
          path: 'release-notes',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/release-notes/releaseNotesView.vue')
        },
        {
          path: 'reports',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/reports/ReportsView.vue')
        },
        {
          path: 'products/:id',
          name: 'product',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/product/EditProductView.vue')
        },
        {
          path: 'newProduct',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/product/AddProductView.vue')
        },

        {
          path: 'shipping/zones/:shippingZoneId',
          name: 'ShippingZonePage',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/shipping/ShippingZoneView.vue')
        },

        {
          path: 'size-charts',
          name: 'size-charts',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/size-charts/SizeChartsView.vue')
        },
        {
          path: 'size-charts/:id',
          name: 'size-charts/:id',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/size-charts/SingleChartView.vue')
        },

        {
          path: 'productImages',
          name: 'productImages',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/images/CurrentProductImagesView.vue'),
          beforeEnter: () => {
            const productStore = useProductStore()
            if (!productStore.product) {
              return { name: 'products' }
            }
          }
        },
        {
          path: 'imageSelection',
          name: 'imageSelection',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/images/ImageSelectionView.vue')
        },
        {
          path: 'newImages',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/images/AddImagesView.vue')
        },
        {
          path: 'orders',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/orders/OrdersView.vue')
        },
        {
          path: 'orders/quick-edit',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/orders/QuickEditOrder.vue')
        },
        {
          path: 'orders/new-orders',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/orders/NewOrders.vue')
        },
        {
          path: 'orders/:id',
          name: 'orders',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/orders/EditOrderView.vue')
        },
        {
          path: '/orders/:id/addProduct',
          name: 'addOrderProduct',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/orders/AddOrderProductview.vue')
        },
        {
          path: 'customers',
          name: 'customers',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/customers/CustomersView.vue')
        },
        {
          path: 'customers/create',
          name: 'createCustomer',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/customers/CreateCustomerView.vue')
        },
        {
          path: 'customers/:id',
          name: 'editCustomer',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/customers/EditCustomerView.vue')
        },
        {
          path: 'coupons',
          name: 'coupons',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/coupons/CouponsView.vue')
        },
        {
          path: '/tools',
          name: 'tools',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/tools/ToolsView.vue')
        },
        {
          path: '/birthdays',
          name: 'birthdays',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/birthdays/BirthdayCheckerView.vue')
        },
        {
          path: '/reviews',
          name: 'reviews',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/reviews/ReviewsView.vue')
        },
        {
          path: '/reviews/:id',
          name: 'review',
          meta: {
            layout: DefaultLayout
          },
          component: () => import('@/views/reviews/ReviewRepliesView.vue')
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/LoginView.vue'),
      beforeEnter: () => {
        const { accessToken } = useAuth()

        if (accessToken) {
          return { name: 'admin' }
        }
      }
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'notFound',
      component: () => import('@/views/NotFoundView.vue')
    }
  ]
})

router.beforeEach(async (to) => {
  const { get } = useCookies(['token'])
  const sessionToken = get('token')

  if (!sessionToken && to.name !== 'login') {
    return { name: 'login' }
  }
})
export default router
